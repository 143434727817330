.myContainer {
  top: 0;
  left: 0;
}

.ui.container {
  margin: 5px !important;
}

.activity .column {
  margin-bottom: 5px !important;
  padding: 0px 8px !important;
}

.activity .column .button {
  padding: 11px 15px !important;
}
